<template>
  <div>
    <header class="flex bg-blue-500">
      <nav class="flex custom-container justify-between py-5">
        <div class="flex">
          <router-link
            class="btn w-56 -ml-12"
            style="color: #274FED;"
            :to="{ name: 'login' }"
          >
            <img :src="logo" alt="CredPal" class="" />
          </router-link>
        </div>
        <div class="flex w-full md:w-3/10 justify-end md:mr-12">
          <template v-if="!checkAuth">
            <div
              class="font-normal text-white cursor-pointer hidden md:block md:mr-4"
            >
              <router-link class="" :to="{ name: 'login' }">
                Sign In</router-link
              >
            </div>
            <div class="font-normal text-white cursor-pointer mt-4 md:mt-0">
              <router-link class="" :to="{ name: 'signup' }"
                >Get Started</router-link
              >
            </div>
          </template>
          <div
            v-else
            class="font-normal text-white cursor-pointer mt-4 md:mt-0 ml-auto"
          >
            <router-link class="" :to="{ name: 'dashboard' }"
              >Dashboard</router-link
            >
          </div>
        </div>
      </nav>
    </header>

    <section
      class="section pb-32 h-full md:h-screen"
      style="background: #274FED;"
    >
      <div class="custom-container">
        <section class="h-full grid grid-cols-1 md:grid-cols-8">
          <div class="w-fullcol-span-1 md:col-span-4 mb-2">
            <div
              class="text-white md:w-11/12 text-xl md:text-2xl lg:text-5xl mb-10 md:mb-14 mt-6 md:mt-10 font-black"
            >
              <!-- Make money working from home with CredPal share & Earn. -->
              Become a Wealth Partner at Credpal and earn up to 4% commissions on referrals.
            </div>

            <div
              class="text-white md:w-9/10 text-sm md:text-base font-normal mb-6 md:mb-12"
            >
              Earn as much as 400,000 naira in commissions monthly 
            </div>

            <div
              class="text-white md:w-9/10 text-xs md:text-sm lg:text-base font-normal mb-12 md:mb-24"
            >
              <div>
                <ion-icon name="checkmark-outline"></ion-icon>
                Withdraw earnings 30days after deposit is made.
              </div>
              <div>
                <ion-icon name="checkmark-outline"></ion-icon>
                Stand a chance to become a super wealth partner and earn a monthly salary asides your commission
              </div>
            </div>

            <div>
              <router-link
                class="btn bg-white text-base focus:border-white shadow-xl"
                style="color: #274FED;"
                :to="{ name: 'signup' }"
              >
                <!-- Register -->
                Join the CredPal Affiliate Program
              </router-link>
            </div>
          </div>
          <div
            class="w-full col-span-1 md:col-span-4 rounded-2xl relative self-center"
          >
            <img class="shadow-2xl rounded-md" :src="learn" alt="" />
          </div>
        </section>
      </div>
    </section>

    <section class="mt-10">
      <div class="custom-container">
        <section>
          <h3 class="text-2xl md:text-4xl font-black mb-10">
            Meet our affiliates
          </h3>
          <div class="grid grid-cols-1 md:grid-cols-12">
            <div class="text-center mt-8 col-span-1 md:col-span-4 mr-2">
              <div
                class="border border-gray p-6 rounded-md relative max-w-sm m-auto"
              >
                <h5 class="text-xl md:text-2xl font-black mb-4">
                  Great experience
                </h5>
                <p class="text-sm">
                  My first sale was better than I expected. Just from sharing on
                  my Whatsapp someone reached out to me and after the approval,
                  I got my commission. That was when I believed CredPal is for
                  real. I haven’t looked back ever since.
                </p>
              </div>
              <div class="w-full flex items-center justify-center -mt-4">
                <img
                  class="rounded-full w-16 flex items-center justify-center text-center"
                  style="height: 3.5rem"
                  :src="triangle"
                  alt=""
                />
              </div>
              <div class="w-full flex items-center justify-center mt-2">
                <span
                  class="w-30px h-30px flex items-center justify-center bg-blue-500 rounded-full text-white text-xs"
                  style="flex: 0 0 30px"
                >
                  OF
                </span>
              </div>
              <div class="font-black text-base">Ore Fadare</div>
              <!-- <div class="font-light text-sm">CEO at ABC Corporation</div> -->
            </div>
            <div class="text-center mt-8 col-span-1 md:col-span-4 mr-2">
              <div
                class="border border-gray p-6 rounded-md relative max-w-sm m-auto"
              >
                <h5 class="text-xl md:text-2xl font-black mb-4">
                  Intuitive Design
                </h5>
                <p class="text-sm">
                  Two of the customers I offered CredPal’s service to were
                  approved for high ticket loans and I got paid a 6 digit
                  figure. I don’t even earn as much as that from my 9-5, the day
                  the alert hit my account, I couldn’t believe it!
                </p>
              </div>
              <!-- <div><span class="home-caret down"></span></div> -->
              <div class="w-full flex items-center justify-center -mt-4">
                <img
                  class="rounded-full w-16 flex items-center justify-center text-center"
                  style="height: 3.5rem"
                  :src="triangle"
                  alt=""
                />
              </div>
              <div class="w-full flex items-center justify-center mt-2">
                <span
                  class="w-30px h-30px flex items-center justify-center bg-blue-500 rounded-full text-white text-xs"
                  style="flex: 0 0 30px"
                >
                  PW
                </span>
              </div>
              <!-- <div class="w-full flex items-center justify-center">
                <img
                  class="rounded-full h-16 w-16 flex items-center justify-center text-center"
                  :src="terms"
                  alt=""
                />
              </div> -->
              <div class="font-black text-base">Philip Williams</div>
              <!-- <div class="font-light text-sm">CEO at ABC Corporation</div> -->
            </div>
            <div class="text-center mt-8 col-span-1 md:col-span-4 mr-2">
              <div
                class="border border-gray p-6 rounded-md relative max-w-sm m-auto"
              >
                <h5 class="text-xl md:text-2xl font-black mb-4">
                  Mindblowing Service
                </h5>
                <p class="text-sm">
                  I got to know about CredPal after we were laid off at my
                  former place of work during Covid 19. With all my referrals,
                  I’m now being paid in 5 figures. It’s been very rewarding.
                </p>
                <!-- <div class="absolute triangle-down"></div> -->
              </div>
              <!-- <div><span class="home-caret down"></span></div> -->
              <div class="w-full flex items-center justify-center -mt-4">
                <img
                  class="rounded-full w-16 flex items-center justify-center text-center"
                  style="height: 3.5rem"
                  :src="triangle"
                  alt=""
                />
              </div>
              <div class="w-full flex items-center justify-center mt-2">
                <span
                  class="w-30px h-30px flex items-center justify-center bg-blue-500 rounded-full text-white text-xs"
                  style="flex: 0 0 30px"
                >
                  EA
                </span>
              </div>
              <div class="font-black text-base">Esther Abiola</div>
              <!-- <div class="font-light text-sm">CEO at ABC Corporation</div> -->
            </div>
          </div>
        </section>
      </div>
    </section>

    <section class="section gray-background my-32">
      <div class="custom-container">
        <h3 class="text-2xl md:text-4xl font-black mb-10">
          Frequently asked questions
        </h3>

        <div class="">
          <div class="w-full">
            <div>
              <div v-for="(faq, index) in filterQuestions" v-bind:key="index">
                <faqAccordion :title="faq.title" :text="faq.answer" />
              </div>
            </div>
          </div>
        </div>

        <div class="text-center mt-8">
          <router-link
            class="btn bg-blue-500 text-white text-base focus:border-white shadow-xl font-light"
            :to="{ name: 'signup' }"
          >
            <!-- Register -->
            Join the CredPal Affiliate Program
          </router-link>
        </div>
      </div>
    </section>

    <section class="my-32">
      <div class="custom-container text-center">
        <h3 class="text-2xl md:text-4xl font-black mb-10">
          Have more questions?
        </h3>
        <p>
          Contact us at
          <a
            href="mailto:hello@credpal.com"
            target="_blank"
            class="text-blue-500 font-bold"
            >affiliate@credpal.com</a
          >
          or call <strong>09074470140</strong> or <strong>08180009193</strong>.
        </p>
        <p>We will be happy to hear from you.</p>

        <div class="md:w-4/12 mx-auto text-center mt-16">
          <ul class="text-center flex justify-center">
            <li class="nav-item mr-4 w-40">
              <router-link class="btn btn-blue w-full" :to="{ name: 'signup' }">
                Register
              </router-link>
            </li>
            <li class="nav-item w-40">
              <button
                type="button"
                @click.prevent="toggleShowContact"
                class="btn btn-gray-outline w-full"
              >
                Contact Us
              </button>
            </li>
          </ul>
        </div>
        <div
          v-if="showContact"
          class="md:w-6/12 mx-auto text-center mt-12 font-hairline"
        >
          Call <span class="font-bold">08180009193</span> or send an email to
          <a href="mailto:hello@credpal.com" target="_blank" class="font-bold"
            >hello@credpal.com</a
          >
        </div>
      </div>
    </section>

    <foot />
  </div>
</template>

<script>
import Faqs from "./data/faq.js";
export default {
  name: "Credvocate",
  components: {
    foot: require("./footer/Index").default,
    faqAccordion: require("./components/FaqAccordion.vue").default,
  },
  data() {
    return {
      salesAcademy: require("@/assets/home/sales_academy.png"),
      boss: require("@/assets/home/boss.png"),
      terms: require("@/assets/home/terms.png"),
      network: require("@/assets/home/network.png"),
      learn: require("@/assets/home/learn.jpg"),
      play: require("@/assets/home/play.svg"),
      check: require("@/assets/home/check.svg"),
      logo: require("@/assets/logo.svg"),
      triangle: require("@/assets/triangle-down.png"),
      showContact: false,
      hover: "free_training",
      personalFaq: Faqs.personal,
      searchField: "",
    };
  },
  computed: {
    checkAuth() {
      return this.$store.state.session.loggedIn;
    },
    filterQuestions() {
      console.log(45, this.personalFaq);
      // return []
      return this.personalFaq?.filter((faq) =>
        faq?.title.toLowerCase().includes(this.searchField.toLowerCase())
      );
    },
  },
  beforeMount() {
    // this.checkAuth();
  },
  methods: {
    toggleHover(name) {
      this.hover = name;
    },
    toggleShowContact() {
      this.showContact = !this.showContact;
    },
  },
};
</script>

<style scoped>
.gray-background {
  background-color: #f9f9fd;
}
.blue-color {
  color: #274fed;
}
.benefit-faint {
  font-weight: 900;
  opacity: 0.16;
}
/* .benefit-faint:hover {
    opacity: 1;
} */
.play-wrapper {
  top: 42%;
  left: -3%;
}
.free-training-border-bottom {
  border-bottom: 4px solid #274fed;
}

.single-curve {
  border-top-right-radius: 40px;
  /* width: 300px; */
}
.single-curve-2 {
  border-bottom-left-radius: 40px;
  /* width: 500px; */
}
.double-curve {
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
  /* width: 650px; */
}

.single-curve-3 {
  /* width: 450px; */
  border-top-right-radius: 40px;
}
.single-curve-4 {
  border-bottom-left-radius: 40px;
  /* width: 300px; */
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: 16px solid #c4c4c4;
  right: 46%;
  top: 100%;
  background-color: white;
}

.home-caret {
  width: 0;
  height: 0;
  display: inline-block;
  border: 20px solid transparent;
}
.home-caret.down {
  border-top-color: #e2e8f0;
}

@media only screen and (min-width: 992px) {
  .play-wrapper {
    top: 37%;
    left: -4%;
  }
}
</style>
