<template>
    <!-- <transition name="fade"> -->
    <div
        class="w-full md:w-4/5 faq-card relative cursor-pointer border-t-2 pt-10 mt-4"
        :class="{ 'faq-card-active': toggleOpen }"
        @click="toggle"
        cursor-pointer
    >
        <div class="flex justify-between faq-body">
            <h4 class="font-bold w-4/5 md:w-full">{{ title }}</h4>
            <button class="modal-close focus:border-white">
                <ion-icon v-if="toggleOpen" name="close"></ion-icon>
                <ion-icon v-if="!toggleOpen" name="add-outline"></ion-icon>
            </button>
        </div>
        <!-- <transition name="fade"> -->
        <div class="leading-8 mt-8 mb-4 font-thin" v-html="text"></div>
        <!-- </transition> -->
    </div>
    <!-- </transition> -->
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            toggleOpen: false
        };
    },
    computed: {},
    methods: {
        toggle() {
            return (this.toggleOpen = !this.toggleOpen);
        }
    }
};
</script>

<style scoped>
/* .fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-enter {
    opacity: 1;
    transition: opacity 0.5s;
} */

.faq-card {
    max-height: 80px;
    /* transition: 0.4s; */
    overflow: hidden;
    transition: max-height 0.9s ease-out;
}

.faq-card-active {
    max-height: 500px;
    overflow-y: scroll;
    transition: max-height 0.8s ease-in;
}

.modal-close {
    top: 2.25rem;
}

@media only screen and (min-width: 992px) {
    .faq-card {
        max-height: 90px;
        overflow: hidden;
        transition: max-height 0.9s ease-out;
    }

    .faq-card-active {
        max-height: 500px;
        transition: max-height 0.9s ease-in;
    }
}
</style>
