<template>
  <div class="form-container md:px-0">
    <form @submit.prevent="submit" class="w-full">
      <h1 class="text-4xl font-bold mb-1 leading-tight">Sign In</h1>
      <div class="text-sm text-gray-600 mb-4 font-normal">
        Sign in to your Wealth partner account.
      </div>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <!-- <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-10">
          <div class="alert-icon mr-3">
            !
          </div>
          <div class="text-xs font-normal">{{ getFormError(form) }}</div>
        </div>
      </template>
      <template v-else>
        <div class="h-12"></div>
      </template> -->

      <form-group
        name="email"
        v-model="form.data.email.value"
        :data="form.data.email"
        className="h-4"
      >
        Enter Email Address
      </form-group>

      <form-group
        type="password"
        name="password"
        v-model="form.data.password.value"
        :data="form.data.password"
      >
        Enter Password
      </form-group>

      <div class="flex flex-row items-center mt-4 mb-10">
        <checkbox
          id="remember"
          class="mr-3"
          v-model="form.data.remember.value"
        />
        <label for="remember" class="text-xs">Keep me signed in</label>
      </div>

      <div class="text-center mb-6">
        <button
          type="submit"
          class="btn btn-blue btn-md w-full"
          :disabled="form.loading"
        >
          <span v-if="form.loading">Submitting...</span>
          <span v-else>Sign In</span>
        </button>
      </div>

      <div class="text-left text-sm">
        <span class="mr-1 opacity-50">Forgot Password?</span>
        <a
          href="#!"
          class="font-medium underline"
          @click.prevent="openPasswordResetModal"
          >Reset Now</a
        >
      </div>
      <div class="text-left text-sm">
        <span class="mr-1 opacity-50">Don't have an account yet? </span>
        <router-link :to="{ name: 'signup' }" class="font-medium underline"
          >Sign up.</router-link
        >
      </div>
    </form>
    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <!-- <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark"> -->

      <div class="text-lg font-bold mb-4">
        Registration successful!
      </div>
      <div class="text-sm mb-10">
        Please login to Continue
      </div>

      <router-link class="btn btn-blue mr-2 mb-2" :to="{ name: 'login' }">
        Go to Login
      </router-link>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10"
      ref="passwordResetModal"
      @close="resetPasswordResetForm"
    >
      <template v-if="forgotPasswordForm.success">
        <h3 class="text-xl font-bold mb-3">Password Request Submitted.</h3>

        <p class="text-sm opacity-50 mb-10">
          Please check your mail for a link to reset your password. Don't forget
          to check your spam folder id you can't find it.
        </p>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-blue btn-md w-full"
            @click.prevent="$refs.passwordResetModal.close"
          >
            Okay
          </button>
        </div>
      </template>

      <template v-else>
        <div class="mb-16">
          <h3 class="text-xl font-bold mb-3">Did you forget your password?</h3>

          <p class="text-sm opacity-50">
            Enter your email address below to find your account.
          </p>
        </div>

        <form @submit.prevent="forgotPassword">
          <template v-if="getFormError(forgotPasswordForm)">
            <div class="alert alert-red-soft -mt-8 mb-12">
              <div class="alert-icon mr-3">
                !
              </div>
              <div class="text-xs font-normal">
                {{ getFormError(forgotPasswordForm) }}
              </div>
            </div>
          </template>

          <form-group
            type="email"
            name="email"
            :form="forgotPasswordForm"
            v-model="forgotPasswordForm.data.email.value"
          >
            Email Address
          </form-group>

          <div class="text-center">
            <button
              type="submit"
              class="btn btn-blue btn-md w-full"
              :disabled="forgotPasswordForm.loading"
            >
              <span v-if="forgotPasswordForm.loading">Finding Account...</span>
              <span v-else>Find My Account</span>
            </button>
          </div>
        </form>
      </template>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      forgotPasswordForm: this.$options.basicForm([
        {
          name: "email",
          rules: "required|email",
        },
      ]),
      form: this.$options.basicForm([
        {
          name: "email",
          rules: "required|email",
        },
        {
          name: "password",
          rules: "required",
        },
        { name: "remember", value: false, rules: "nullable" },
      ]),
    };
  },
  computed: {
    sessionExpired() {
      return this.$route.query.expired;
    },
  },
  methods: {
    async forgotPassword() {
      if (!this.validateForm(this.forgotPasswordForm)) {
        return false;
      }

      this.forgotPasswordForm.error = false;
      this.forgotPasswordForm.loading = true;

      await this.$post({
        url: `${this.$baseurl}/affiliate/password/request`,
        data: {
          email: this.forgotPasswordForm.data.email.value,
        },
        success: () => {
          this.forgotPasswordForm.success = true;
        },
        error: (error) => {
          this.forgotPasswordForm.error = error;
          this.mapFormErrors(
            this.forgotPasswordForm,
            error?.response?.data?.errors
          );
        },
      });

      this.forgotPasswordForm.loading = false;
    },
    navigate() {
      this.$router.push({ name: "root" });
    },
    openPasswordResetModal() {
      this.$refs.passwordResetModal.open();
    },
    resetPasswordResetForm() {
      setTimeout(() => {
        this.forgotPasswordForm = this.resetForm(this.forgotPasswordForm);
      }, 500);
    },
    async submit() {
      if (!this.validateForm(this.form)) {
        return false;
      }

      this.form.error = false;
      this.form.loading = true;
      await this.$post({
        url: `${this.$baseurl}/affiliate/login`,
        data: {
          email: this.form.data.email.value,
          password: this.form.data.password.value,
        },
        success: (response) => {
          this.$store.dispatch("session/login", {
            token: response.data.data.token,
            user: response.data.data.affiliate,
            remember: this.form.data.remember.value === true,
          });

          this.$root.$emit("login");
          this.$root.$emit("global", {
            event: "loggedIn",
            params: [response.data.data.affiliate],
          });

          if (this.user.change_password_at) {
            this.$router.push({ name: "dashboard" });
          } else {
            this.$router.push({ name: "update-password" });
          }
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        },
      });

      this.form.loading = false;
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  .form-container {
    /* width: 290px; */
    width: 90vw;
  }
}
@media only screen and (min-width: 769px) {
  .form-container {
    width: 400px;
  }
}
</style>
