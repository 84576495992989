const faqs = {
    personal: [

        {
            title: "What is CredPal?",
            answer: `CredPal is a credit card company that offers credit cards and loans to individuals and businesses. How it works: <ul><li>After customers have been approved and a credit limit is given to them. Customers can use their cards for personal needs and purchases.</li><li>On their billing cycle which is their salary date, customers pay back what they have spent on their credit card with the accrued interest.</li><li>After their repayment, their account will be automatically funded again to their initial credit limit</li></ul> To learn more about CredPal, you can visit our website <a href="https://app.credpal.com/register" class='text-blue-500 font-bold'>here</a>.`
        },
        {
            title: "What is CredPal affiliate program?",
            answer: `CredPal affiliate program allows you to earn commissions for referring customers to CredPal. Joining our affiliate program gives you the opportunity to be your own boss and earn conveniently from the comfort of your house. To learn more about the program, you can visit our blog <a href="https://app.credpal.com/register" class='text-blue-500 font-bold'>here</a>.`
        },
        {
            title: "What does it cost to be a CredPal affiliate marketer?",
            answer: "Signing up with CredPal as an affiliate marketer costs nothing. It is totally free!"
        },
        {
            title: "What do you call a CredPal affiliate?",
            answer: "We value our CredVocates so much that we coined out a unique name for them. We call them 'CredVocate' because they help us spread the gospel of CredPal and help us acquire new customers."
        },
        {
            title: "How do I sign up as a CredPal affiliate?",
            answer: `Signing up as a CredVocate is just a click away. Click <a href="https://affiliate.credpal.com/register" class='text-blue-500 font-bold'>here</a>. to register`
        },
        {
            title: "Do I need a special qualification to be a CredVocate? ",
            answer: "No, you don’t. Our affiliate program is open to students, job seekers, full-time employees, whatever level. Our affiliate program accommodates anyone who has the influence and platform to help us acquire new customers."
        },
        {
            title: "How much do I get per referred sale?",
            answer: "For every person you refer, you get 200 Naira and for every customer that gets approved, you get 1,000 Naira."
        },
        {
            title: "Can I promote through online ads?",
            answer: "Yes! We have CredVocates who use this platform to promote CredPal. You can use all the platforms that would be of great benefit to you in order to earn more because there is no limit to how much you earn as a CredVocate. However, ensure that the content of the ads aligns with what we promote."
        },
        {
            title: "How does CredPal track sales/ referrals?",
            answer: "CredPal affiliate program is powered by leading affiliate tracking software in the world. When the visitor follows your affiliate link to our site, our affiliate system registers this referral. You can also see the customers who signed up through your link on your dashboard. This process is absolutely automatic. All your referrals will be properly tracked."
        },
        {
            title: "How and when will I be paid?",
            answer: "As a CredVocate, you get commission over every approved individual that registers through your link. Your commission is sent to your CredPal wallet at the end of the month. Then you can choose to transfer the money from your wallet into your bank account. "
        },
        {
            title: "How can I check my commission balance?",
            answer: "You can check your commission on your CredPal dashboard. To check your commission, log in to your CredPal dashboard here, once you log in successfully, you will find your total earnings by the right."
        },
    ]
}
export default faqs;
