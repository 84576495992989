<template>
  <footer>
    <div class="custom-container">
      <div class="pb-24 border-b border-gray-800">
        <div class="grid grid-cols-1 md:grid-cols-12 gap-y-10 gap-x-24">
          <div class="col-span-1 md:col-span-4">
            <div class="mb-6 md:mb-10 opacity-75">
              <logo-grey />
            </div>
            <div class="text-12 leading-7">
              CredPal is a revolutionary credit solution geared towards
              providing seamless credit access for businesses and individuals
              across developing economies.
            </div>
          </div>
          <div class="col-span-1 md:col-span-8">
            <div class="grid grid-cols-2 md:grid-cols-4 row-gap-16">
              <div class="col-span-1 mb-10 md:mb-0">
                <div
                  class="text-14 font-bold font-sans mb-6 md:mb-10 text-white"
                >
                  Products
                </div>
                <ul class="footer-nav nav">
                  <li class="nav-item">
                    <router-link :to="{ name: 'https://www.credpal.com/savings-and-investments' }" class="nav-link"
                      >Investment</router-link
                    >
                  </li>
                </ul>
              </div>
              <div class="col-span-1 mb-10 md:mb-0">
                <div
                  class="text-14 font-bold font-sans mb-6 md:mb-10 text-white"
                >
                  Legal
                </div>
                <ul class="footer-nav nav">
                  <li class="nav-item">
                    <router-link :to="{ name: 'terms' }" class="nav-link"
                      >Terms of Use</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link :to="{ name: 'conduct' }" class="nav-link"
                      >Code of Conduct</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link :to="{ name: 'privacy' }" class="nav-link"
                      >Privacy policies</router-link
                    >
                  </li>
                  <!-- <li class="nav-item">
                    <a href="" class="nav-link">Disclaimer</a>
                  </li> -->
                </ul>
              </div>
              <div class="col-span-1">
                <div
                  class="text-14 font-bold font-sans mb-6 md:mb-10 text-white"
                >
                  Support
                </div>
                <ul class="footer-nav nav">
                  <li class="nav-item">
                    <router-link :to="{ name: 'faq' }" class="nav-link"
                      >FAQs</router-link
                    >
                  </li>
                  <!-- <li class="nav-item">
                    <a href="" class="nav-link">Another Button</a>
                  </li> -->
                </ul>
              </div>
              <div class="col-span-1">
                <div
                  class="text-14 font-bold font-sans mb-6 md:mb-10 text-white"
                >
                  Connect with us
                </div>
                <div class="flex flex-row mb-10">
                  <a
                    href="https://facebook.com/credpal"
                    target="_blank"
                    class="mr-3"
                  >
                    <img
                      :src="facebook"
                      alt="CredPal Facebook"
                    />
                  </a>
                  <a
                    href="https://twitter.com/CredPal"
                    target="_blank"
                    class="mr-3"
                  >
                    <img
                      :src="twitter"
                      alt="CredPal Twitter"
                    />
                  </a>
                  <a
                    href="https://instagram.com/credpalhq"
                    target="_blank"
                    class="mr-3"
                  >
                    <img
                      :src="instagram"
                      alt="CredPal Instagram"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/credpal/"
                    target="_blank"
                    class="mr-3"
                  >
                    <img
                      :src="linkedin"
                      alt="CredPal Linkedin"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCt4t-3YIuMolzLWWj4uHKKg"
                    target="_blank"
                  >
                    <img
                      :src="youtube"
                      alt="CredPal Youtube"
                    />
                  </a>
                </div>

                <div class="text-14 font-bold font-sans mb-6 text-white">
                  Contact
                </div>
                <ul class="footer-nav nav">
                  <li class="nav-item">
                    <a
                      href="mailto:hello@credpal.com"
                      target="_blank"
                      class="nav-link"
                      >hello@credpal.com</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-6 flex flex-wrap">
        <div class="text-xs">
          Copyright © {{ year() }} &nbsp;•&nbsp; All Rights Reserved
        </div>
        <a
          href="#!"
          class="ml-auto text-xs flex items-center"
          data-scroll-to-top
        >
          <ion-icon name="chevron-up-outline" class="mr-1"></ion-icon>
          Back to Top
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import $ from "jquery";

export default {
  components: {
    logoGrey: require("./components/LogoGrey.vue").default,
  },
  data: () => {
    return {
      year: () => new Date().getFullYear(),
      facebook: require("@/assets/social/facebook.svg"),
      twitter: require("@/assets/social/twitter.svg"),
      instagram: require("@/assets/social/instagram.svg"),
      linkedin: require("@/assets/social/linkedin.svg"),
      youtube: require("@/assets/social/youtube.svg"),
    };
  },
  mounted() {
    $("body").on("click", "[data-scroll-to-top]", function(event) {
      event.preventDefault();

      $("body, html").animate(
        {
          scrollTop: 0,
        },
        2000
      );
    });
  },
};
</script>
